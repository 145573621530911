/*! js-cookie v3.0.0-rc.0 | MIT */
!function(e,t){"object"==typeof exports&&"undefined"!=typeof module?module.exports=t():"function"==typeof define&&define.amd?define(t):(e=e||self,function(){var r=e.Cookies,n=e.Cookies=t();n.noConflict=function(){return e.Cookies=r,n}}())}(this,function(){"use strict";function e(e){for(var t=1;t<arguments.length;t++){var r=arguments[t];for(var n in r)e[n]=r[n]}return e}var t={read:function(e){return e.replace(/%3B/g,";")},write:function(e){return e.replace(/;/g,"%3B")}};return function r(n,i){function o(r,o,u){if("undefined"!=typeof document){"number"==typeof(u=e({},i,u)).expires&&(u.expires=new Date(Date.now()+864e5*u.expires)),u.expires&&(u.expires=u.expires.toUTCString()),r=t.write(r).replace(/=/g,"%3D"),o=n.write(String(o),r);var c="";for(var f in u)u[f]&&(c+="; "+f,!0!==u[f]&&(c+="="+u[f].split(";")[0]));return document.cookie=r+"="+o+c}}return Object.create({set:o,get:function(e){if("undefined"!=typeof document&&(!arguments.length||e)){for(var r=document.cookie?document.cookie.split("; "):[],i={},o=0;o<r.length;o++){var u=r[o].split("="),c=u.slice(1).join("="),f=t.read(u[0]).replace(/%3D/g,"=");if(i[f]=n.read(c,f),e===f)break}return e?i[e]:i}},remove:function(t,r){o(t,"",e({},r,{expires:-1}))},withAttributes:function(t){return r(this.converter,e({},this.attributes,t))},withConverter:function(t){return r(e({},this.converter,t),this.attributes)}},{attributes:{value:Object.freeze(i)},converter:{value:Object.freeze(n)}})}(t,{path:"/"})});

var cookies = Cookies.withConverter({
    read: function (value, name) {
        if (name === 'cookie-consent') {
            return atob(value);
        }
        return Cookies.converter.read(value, name);
    },
    write: function (value, name) {
        if (name === 'cookie-consent') {
            return btoa(value);
        }
    }
});
document.addEventListener("DOMContentLoaded", function() {
    if (typeof cookies.get('cookie-consent') == 'undefined') {
        var show = true;

        if (typeof cookieConsentHideOnLoad != 'undefined') {
            show = false;
        }

        if (document.body.classList.contains('hide-cookie-consent')) {
            show = false;
        }

        if (show) {
            window.setTimeout(function() {
                CookieConset.show();
            }, 500);
        }
    } else {
        var consentData = JSON.parse(cookies.get('cookie-consent'));
        if (window.console) {
            console.log('consentData', consentData);
        }
        CookieConset.handle(consentData);
    }

    CookieConset.status.update();

    const button = document.querySelector('.consent-change-settings');

    if (button) {
        button.addEventListener('click', function(event) {
            CookieConset.show();
            event.preventDefault();
        });
    }

    var buttons = document.querySelectorAll('.consent-accept-all');

    [].forEach.call(buttons, function(button) {
        button.addEventListener('click', function(event) {

            event.preventDefault();

            var consentData = {};

            var inputs = document.querySelectorAll('.settings-content-item input');
            [].forEach.call(inputs, function(input) {
                var name = input.getAttribute('name');
                if (name != '') {
                    consentData[name] = true;
                }
            });

            consentData['required'] = true;

            const expires = (typeof cookieConsentExpires != 'undefined') ? cookieConsentExpires : 365;
            cookies.set('cookie-consent', JSON.stringify(consentData), { path: '/', expires: expires });

            CookieConset.handle(consentData);
            CookieConset.hide();
        });
    });

    document.querySelector('.consent-required-only').addEventListener('click', function(event) {

        event.preventDefault();

        var consentData = {};

        var inputs = document.querySelectorAll('.settings-content-item input');
        [].forEach.call(inputs, function(input) {
            var name = input.getAttribute('name');
            if (name != '') {
                consentData[name] = false;
            }
        });

        consentData['required'] = true;

        const expires = (typeof cookieConsentExpires != 'undefined') ? cookieConsentExpires : 365;
        cookies.set('cookie-consent', JSON.stringify(consentData), { path: '/', expires: expires });

        CookieConset.handle(consentData);
        CookieConset.hide();
    });

    buttons = document.querySelectorAll('.consent-settings');
    [].forEach.call(buttons, function(button) {
        button.addEventListener('click', function(event) {
            event.preventDefault();
            CookieConset.settings.show();
        });
    });

    const settingsOptions = document.querySelectorAll('.settings-nav-item');
    [].forEach.call(settingsOptions, function(settingsOption) {
        settingsOption.addEventListener('click', function(event) {

            if (document.querySelector('.settings-nav-item.active')) {
                document.querySelector('.settings-nav-item.active').classList.remove('active');
            }

            this.classList.add('active');
            const id = this.dataset.target.substr(1);

            if (id != '' && document.getElementById(id) != null) {
                var active = document.querySelector('.settings-content-item.active');
                if (active) {
                    active.classList.remove('active');
                }
                document.getElementById(id).classList.add('active');
            }

            event.preventDefault();
        });
    });

    document.querySelector('.consent-settings-save').addEventListener('click', function(event) {

        event.preventDefault();

        var consentData = {};
        var inputs = document.querySelectorAll('.settings-content-item input');
        [].forEach.call(inputs, function(input) {
            var name = input.getAttribute('name');
            if (name != '') {
                if (name == 'required') {
                    consentData[name] = true;
                }
                else {
                    consentData[name] = input.checked;
                }
            }
        });

        const expires = (typeof cookieConsentExpires != 'undefined') ? cookieConsentExpires : 365;
        cookies.set('cookie-consent', JSON.stringify(consentData), { path: '/', expires: expires });

        CookieConset.handle(consentData);
        CookieConset.hide();

    });

    document.querySelector('.settings-header').addEventListener('click', function(event) {
        event.preventDefault();
        CookieConset.settings.hide();
    });

});


var CookieConset = {
    show: function() {
        document.querySelector('.cookie-consent').classList.add('consent-show');
        document.querySelector('.cookie-consent-overlay').style.display = 'block';
        return false;
    },
    hide: function() {
        document.querySelector('.cookie-consent').classList.remove('consent-show');
        document.querySelector('.cookie-consent-overlay').style.display = 'none';
        CookieConset.settings.hide();
        CookieConset.status.update();
    },

    settings: {
        show: function() {
            CookieConset.show();
            document.querySelector('.cookie-consent-settings').classList.add('active');
        },
        hide: function() {
            document.querySelector('.cookie-consent-settings').classList.remove('active');
        }
    },

    status: {
        update: function() {
            if (document.querySelectorAll('.cookie-consent-status').length > 0) {
                if (typeof cookies.get('cookie-consent') != 'undefined') {
                    document.querySelector('.cookie-consent-status .not-selected').style.display = 'none';
                    document.querySelector('.cookie-consent-status .accepted').style.display = 'none';
                    document.querySelector('.cookie-consent-status .not-accepted').style.display = 'block';

                    var consentData = JSON.parse(cookies.get('cookie-consent'));
                    for (var key in consentData) {
                        var value = consentData[key];
                        if (key != 'required' && value == true) {
                            document.querySelector('.cookie-consent-status  .cookie-consent-item-' + key + ' span').style.display = 'none';
                            document.querySelector('.cookie-consent-status  .cookie-consent-item-' + key + ' span.accepted').style.display = 'block';
                        }
                    }
                }
                else {
                    document.querySelector('.cookie-consent-status .not-selected').style.display = 'block';
                    document.querySelector('.cookie-consent-status .accepted').style.display = 'none';
                    document.querySelector('.cookie-consent-status .not-accepted').style.display = 'none';
                }
            }
        }
    },

    handle: function(data)
    {
        for (var key in data) {
            var object = data[key];
            if (object == true) {
                var scripts = document.querySelectorAll('script[data-consent-context="' + key + '"');

                var event = new CustomEvent("consent-activated-" + key);
                document.dispatchEvent(event);
                if (window.console) {
                    console.log('event consent-activated-' + key);
                }

                [].forEach.call(scripts, function(script) {

                    var copy = script.cloneNode(true);
                    copy.setAttribute('type', 'text/javascript');

                    script.parentElement.appendChild(copy)
                    script.parentElement.removeChild(script);
                });

                var iframes = document.querySelectorAll('iframe[data-consent-context="' + key + '"');

                [].forEach.call(iframes, function(iframe) {
                    iframe.setAttribute('src', iframe.dataset.src);
                    iframe.contentWindow.location.replace(iframe.dataset.src);
                });
            }
            else {
                var iframes = document.querySelectorAll('iframe[data-consent-context="' + key + '"');

                var event = new CustomEvent("consent-deactivated-" + key);
                document.dispatchEvent(event);
                if (window.console) {
                    console.log('event consent-deactivated-' + key);
                }

                [].forEach.call(iframes, function(iframe) {
                    iframe.setAttribute('src', iframe.dataset.blocked);
                    iframe.contentWindow.location.replace(iframe.dataset.blocked);
                });
            }

        }

    }
}


